@import "functions";

$html-font-size: 15px;

// $backGroundColor: hsla(247, 15%, 12%, 1);
$backGroundColor: rgb(244, 247, 252);
$fontColorMain:  rgba(100, 119, 136, 1);
$outstandingColor: hsla(32, 100%, 62%, 1);
$buttonNonActive: hsla(275, 20%, 90%, 0.1);
$buttonActive: hsla(266, 100%, 69%, 1);

$headerHeight: rem(58px);
$headerHeightTablet: rem(45px);
$paddingTop: rem(57.5px);
$paddingBottom: rem(42px);


$marginTop: rem(63px);
$marginBottom: rem(79px);

$marginBottomFooter: rem(48px);