@use "variables" as v;

:root {
  --main-color: hsla(32, 100%, 62%, 1);
  --dark-blue: rgba(100, 119, 136, 1);
  --window-width: 0;
}

/* ---nullification--- */
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-family: Manrope;
  background-color: v.$backGroundColor;
  color: v.$fontColorMain;
  line-height: 1;
  font-size: v.$html-font-size;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 0;
  }
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  pointer-events: none;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/* ::-webkit-scrollbar {display: none;} */
/* ---end nullification--- */

.linkFill {
  position: absolute;
  inset: 0 0 0 0;
  opacity: 0;
}

$primary-color: white;
@import "loaders.css/src/animations/line-scale-pulse-out-rapid.scss";

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

.fill {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
