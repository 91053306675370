@import "~/src/styles/mixins";

.mainPage {
  width: 100%;
  height: var(--height, 100vh);
}

.header {
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1720px;
  padding: 0 40px;
  z-index: 15;

  @media (max-width: 980px) {
    z-index: 3;
  }
}
.logoWrapper {
  width: 124px;
  height: 76px;

  @media (max-width: 1420px) {
    width: 94px;
    height: 56px;
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.buttonWrapper {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 23%;
  // right: 434px;
  // top: 51%;
  // left: 50%;
  // transform: translate(50%, -50%);
  z-index: 2;

  // @media (max-width: 1500px) {
  //   right: 334px;
  // }
  // @media (max-width: 1300px) {
  //   right: 224px;
  // }
  // @media (max-width: 1100px) {
  //   right: 174px;
  // }
  // @media (max-width: 1040px) {
  //   right: 84px;
  // }

  @media (max-width: 800px) {
    top: initial;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
  }

  .button {
    min-width: 340px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(360deg, #ff9415 0%, #ffbf73 100%), #ff9415;
    border-radius: 37px;
    transition: 0.3s;

    font-family: HeadingNow;
    font-size: 52px;
    color: white;
    text-transform: uppercase;

    @media (max-width: 1700px) {
      min-width: 240px;
      height: 70px;
      font-size: 35px;
      border-radius: 23px;
    }
    @media (max-width: 800px) {
      min-width: 220px;
      height: 60px;
      font-size: 32px;
      border-radius: 20px;
    }

    @include desktop() {
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}

.backgroundWrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: var(--height, 100vh);
  width: 100%;
  z-index: 1;

  .backgroundDesktop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    // min-height: 100%;

    object-fit: cover;
    object-position:  75% center;

    // @media (max-width: 1500px) {
    //   // transform: translate(15%, -50%);
    //   transform: translate(160px, -50%);
    // }
    // @media (max-width: 1300px) {
    //   transform: translate(210px, -50%);
    // }
    // @media (max-width: 1100px) {
    //   transform: translate(260px, -50%);
    // }
    // @media (max-width: 1040px) {
    //   transform: translate(360px, -50%);
    // }

    @media (max-width: 800px) {
      display: none;
    }
  }
  .backgroundMobile {
    object-position: 85% 25%;
    display: none;
    @media (max-width: 800px) {
      display: block;
    }
  }
}

.footer {
  padding: 0 20px;
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translateX(-50%);
  width: 100%;
  max-width: 460px;
  z-index: 2;
}
